import { FC } from 'react'
import { Button, Dialog, Spinner, SpinnerSize } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { usePaymentController } from 'client/controllers/paymentsController'
import { STATE } from 'lib/constants/api'

type TProps = {}

const PaymentDialog: FC<TProps> = () => {
  const { isOpen, setIsOpen, loadingState, redirectUrl } = usePaymentController()

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      backdropClassName="bg-backdrop"
      className="h-full w-full max-md:my-0 md:w-[calc(100%-4rem)] lg:w-10/12 lg:px-0 2xl:w-8/12"
    >
      <div className="relative flex h-[calc(40vw)] items-center justify-center max-2xl:h-[calc(60vw)] max-lg:h-[calc(100vh)]">
        {loadingState === STATE.LOADING ? (
          <div className="">
            <Spinner size={SpinnerSize.LARGE} />
          </div>
        ) : redirectUrl ? (
          <iframe src={redirectUrl} className="h-full w-full" />
        ) : (
          <div />
        )}
        <div className="absolute right-3 top-3" onClick={() => setIsOpen(false)}>
          <Button icon={IconNames.Cross} className="relative" />
        </div>
      </div>
    </Dialog>
  )
}

export { PaymentDialog }
