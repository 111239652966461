import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

export const captionStyle = ctl(`whitespace-nowrap`)

const MainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)

  return (
    <ResponsiveBlock size="wide" className="h-auto">
      <div className="flex w-full justify-between gap-6">
        <div className="flex flex-col gap-2 max-lg:w-full max-lg:justify-center max-lg:pb-8 max-lg:pt-0">
          <Text
            tagName="span"
            className="font-secondary-regular---30pt whitespace-pre uppercase text-text-black-alt max-xl:font-secondary-regular---22pt max-lg:hidden"
          >
            {t('main_block.title')}
          </Text>
          <Text
            tagName="span"
            className="font-tertiary-medium---18pt text-text-secondary-alt max-sm:font-tertiary-medium---13pt max-lg:text-center"
          >
            {t('main_block.caption')}
          </Text>
        </div>
        <div className="flex flex-col gap-8 max-xl:gap-4 max-lg:hidden">
          <div className="flex ">
            <Text tagName="span" className="font-tertiary-semibold---13pt text-text-gray">
              {t('main_block.card.title')}
            </Text>
          </div>
          <ul className="flex flex-col gap-2">
            <li className="flex items-center gap-2">
              <Text tagName="span" className="font-tertiary-semibold---13pt text-text-gray">
                {t('main_block.card.number')}
              </Text>
              <Text tagName="span" className="font-tertiary-semibold---13pt text-text-gray">
                4111 1111 1111 1111
              </Text>
            </li>
            <li className="flex items-center gap-2">
              <Text tagName="span" className="font-tertiary-semibold---13pt text-text-gray">
                {t('main_block.card.date')}
              </Text>
              <Text tagName="span" className="font-tertiary-semibold---13pt text-text-gray">
                01/25
              </Text>
            </li>
            <li className="flex items-center gap-2">
              <Text tagName="span" className="font-tertiary-semibold---13pt text-text-gray">
                {t('main_block.card.cvv')}
              </Text>
              <Text tagName="span" className="font-tertiary-semibold---13pt text-text-gray">
                any 3 digits
              </Text>
            </li>
          </ul>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { MainBlock }
