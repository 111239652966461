import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Plus } from '@blueprintjs/icons'
import ctl from '@netlify/classnames-template-literals'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import Analytics from 'images/analytics.png'
import Mm from 'images/svg/mm.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Chart } from './components/Chart/Chart'

type TProps = {}

const balanceClassName = ctl(`flex flex-1 flex-col items-center justify-between text-center`)
const valueClassName = ctl(`font-primary-bold---12pt scale-75 lg:font-primary-bold---24pt lg:scale-100`)

const AnalyticsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.OurModelPage)

  return (
    <ResponsiveBlock size="medium" type="secondary">
      <div className="flex min-h-screen w-full flex-col gap-8 py-20 text-text-black">
        <Text tagName="h2" className="font-secondary-regular---22pt text-center lg:font-secondary-regular---36pt">
          {t('analytics_block.title')}
        </Text>
        <Text tagName="span" className="font-primary-regular---15pt text-center">
          {t('analytics_block.caption')}
        </Text>
        <div className="relative flex w-full flex-col rounded-2xl bg-surface-white shadow-secondary">
          <div className="flex w-full items-center justify-between px-6 py-4">
            <div className="flex items-center gap-3">
              <div className="flex h-2.5 w-2.5 rounded-half bg-functional-error" />
              <div className="flex h-2.5 w-2.5 rounded-half bg-functional-attention" />
              <div className="flex h-2.5 w-2.5 rounded-half bg-functional-success" />
            </div>
            <Plus size={16} className="text-text-secondary" />
          </div>
          <div className="flex w-full flex-col gap-4 p-2 sm:p-8">
            <div className="flex w-full items-center justify-between">
              <Text tagName="span" className="font-primary-bold---24pt">
                {t('analytics_block.dashboard')}
              </Text>
              <div className="flex">
                <Mm />
              </div>
            </div>
            <div className="flex w-full justify-between bg-surface-secondary p-4">
              <div className={balanceClassName}>
                <Text tagName="span" className="font-primary-regular---12pt lg:font-primary-regular---15pt">
                  {t('analytics_block.unsettled_balance')}
                </Text>
                <ScrollAppear>
                  <Text tagName="span" className={valueClassName}>
                    $783.56
                  </Text>
                </ScrollAppear>
              </div>
              <div className={balanceClassName}>
                <Text tagName="span" className="font-primary-regular---12pt lg:font-primary-regular---15pt">
                  {t('analytics_block.settled_balance')}
                </Text>
                <ScrollAppear delay={1}>
                  <Text tagName="span" className={valueClassName}>
                    $210,735.43
                  </Text>
                </ScrollAppear>
              </div>
              <div className={balanceClassName}>
                <Text tagName="span" className="font-primary-regular---12pt lg:font-primary-regular---15pt">
                  {t('analytics_block.in_progress')}
                </Text>
                <ScrollAppear delay={0.8}>
                  <Text tagName="span" className={valueClassName}>
                    $23,345.03
                  </Text>
                </ScrollAppear>
              </div>
            </div>
            <Chart />
          </div>
          <ScrollAppear>
            <img
              src={Analytics}
              className="absolute -bottom-32 -right-20 scale-50 md:-bottom-12 md:-right-8 md:scale-75 lg:-bottom-20 lg:-right-16 lg:scale-100"
            />
          </ScrollAppear>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { AnalyticsBlock }
