import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import Sharp from 'images/demo/sharp.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const Header: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)

  return (
    <header className="flex w-full py-5 max-xl:py-2 max-lg:py-5">
      <ResponsiveBlock size="wide" className="">
        <div className="flex h-12 w-full items-center max-lg:justify-center">
          <div className="flex items-center gap-2">
            <Sharp />
            <Text
              tagName="span"
              className="font-secondary-regular---22pt uppercase text-text-black-alt max-sm:font-secondary-regular---15pt max-sm:whitespace-nowrap"
            >
              {t('header.title')}
            </Text>
          </div>
        </div>
      </ResponsiveBlock>
    </header>
  )
}

export { Header }
