import { FC } from 'react'
import { Button, Text } from '@blueprintjs/core'
import { usePaymentController } from 'client/controllers/paymentsController'

import { TCardItem } from '../../CardBlock'

import { Rating } from './components/Rating/Rating'

type TProps = {
  item: TCardItem
  index: number
}

const Card: FC<TProps> = ({ item, index }) => {
  const { setIsOpen, create } = usePaymentController()

  return (
    <li className="flex w-full flex-col overflow-hidden rounded-3xl shadow-main">
      <div className="flex h-[calc(15vw)] w-full max-xl:h-[calc(12vw)] max-lg:h-[calc(40vw)]">
        <img src={item.image} className="w-full object-cover" />
      </div>
      <div className="flex flex-1 flex-col gap-8 p-6 max-xl:gap-4 max-xl:p-4 max-lg:gap-8 max-lg:p-6">
        <div className="flex items-center justify-between">
          <Text
            tagName="span"
            className="font-tertiary-bold---21pt max-sm:font-tertiary-bold---15pt"
            style={{ color: '#223A78' }}
          >
            {`€${item.price}.00`}
          </Text>
          <Rating value={item.rating} />
        </div>
        <div className="flex h-full w-full flex-col justify-between">
          <div className="flex flex-1 flex-col gap-4 text-text-black">
            <Text tagName="span" className="font-tertiary-bold---15pt uppercase">
              {item.title}
            </Text>
            <Text tagName="p" className="font-tertiary-regular---12pt">
              {item.description}
            </Text>
          </div>
          <div className="flex w-full justify-center">
            <Button
              className="font-tertiary-bold---15pt !bg-transparent uppercase !text-palette-darkblue !shadow-none max-xl:!p-0"
              onClick={() => {
                setIsOpen(true)
                create({ title: `payment-${index + 1}`, description: item.description, price: item.price })
              }}
            >
              + Buy
            </Button>
          </div>
        </div>
      </div>
    </li>
  )
}

export { Card }
