import { FC } from 'react'

import { ButtonBlock } from './components/ButtonBlock/ButtonBlock'
import { DocsMainBlock } from './components/DocsMainBlock/DocsMainBlock'

type TProps = {}

const DocsPageContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <DocsMainBlock />
      <ButtonBlock />
    </div>
  )
}

export { DocsPageContent }
