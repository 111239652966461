import { FC } from 'react'

import { CardBlock } from './components/CardBlock/CardBlock'
import { MainBlock } from './components/MainBlock/MainBlock'

type TProps = {}

const DemoPageContent: FC<TProps> = () => {
  return (
    <div className="flex h-full w-full flex-col gap-20 pt-4 max-2xl:gap-12 max-xl:gap-8 max-xl:pt-0 max-lg:gap-0">
      <MainBlock />
      <CardBlock />
    </div>
  )
}

export { DemoPageContent }
