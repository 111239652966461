import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import Pdf from 'images/svg/pdf.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const DOCS_LINKS: string[] = [
  'https://drive.google.com/file/d/11CvSXvbmCNzyasMN0O_BAB_dTA5nNFhw/view?usp=sharing',
  'https://drive.google.com/file/d/1Ka4AE9qOO2E3Bm5UkslcJFkiJzoZFCUq/view?usp=sharing',
  'https://drive.google.com/file/d/1IV-sCefO3Rl4kvrUi0KQ88j8C5ny9gez/view?usp=sharing',
  'https://drive.google.com/file/d/1vcDhitAu8rE2TmlYs_xbZJi73oDU5ykW/view?usp=sharing',
  'https://drive.google.com/file/d/1I759tT6JysCbsvhGZ-ue-CMOSQ9PcjiH/view?usp=sharing',
]

const DocsMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DocsPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col items-center gap-12 py-12  text-text-black lg:gap-20 lg:pt-30">
        <Text tagName="h1" className="font-secondary-regular---30pt text-center lg:font-secondary-bold---52pt">
          {t('main_block.title')}
        </Text>
        <Text
          tagName="span"
          className="font-primary-regular---15pt text-center font-bold lg:font-primary-regular---19pt lg:mb-20"
        >
          {t('main_block.caption')}
        </Text>
        <ul className="flex w-full flex-col flex-wrap items-center gap-8 md:flex-row md:justify-center">
          {DOCS_LINKS.map((link, index) => (
            <li key={index} className="flex w-full items-center gap-3 sm:w-auto">
              <div className="flex w-10">
                <img src={Pdf} className="object-contain" />
              </div>
              <a
                href={link}
                target="_blank"
                className="p-1 text-text-black transition-colors hover:text-text-tertiary hover:no-underline"
                rel="noreferrer noindex nofollow"
              >
                <Text tagName="span" className="font-secondary-regular---15pt">
                  {t(`main_block.list.${index + 1}.title`)}
                </Text>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </ResponsiveBlock>
  )
}

export { DocsMainBlock }
