import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import InstagramIcon from 'images/svg/instagram.svg?react'
import LinkedInIcon from 'images/svg/linkedin.svg?react'
import TelegramIcon from 'images/svg/telegram.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

import { AvailabilityBlock } from '../AvailabilityBlock/AvailabilityBlock'
import { FooterLink } from '../FooterLink/FooterLink'

import { IconLink } from './components/IconLink/IconLink'
import { LinksColumn } from './components/LinksColumn/LinksColumn'

type TProps = {}

const LinksBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.Common)

  return (
    <div className="flex w-full flex-wrap">
      <LinksColumn title={t('links.titles.company')}>
        <FooterLink href="/about">{t('links.about')}</FooterLink>
        <FooterLink href="/merchant-of-record">{t('links.mor')}</FooterLink>
        <FooterLink href="/sonar">{t('links.sonar')}</FooterLink>
        <FooterLink href="/gate">{t('links.gate')}</FooterLink>
        <FooterLink href="/digital-distribution">{t('links.digital_distribution')}</FooterLink>
        <FooterLink href="/website-builder">{t('links.brick')}</FooterLink>
        <FooterLink href="/pricing">{t('links.pricing')}</FooterLink>
        <FooterLink href="/security-operations-center">{t('links.soc')}</FooterLink>
        <FooterLink href="/careers">{t('links.careers')}</FooterLink>
      </LinksColumn>
      <LinksColumn title={t('links.titles.content')}>
        <FooterLink href="/magazine">{t('links.magazine')}</FooterLink>
        <FooterLink href="/payment-methods">{t('links.payment_methods')}</FooterLink>
        <FooterLink href="/why-merchant-of-record">{t('links.why_mor')}</FooterLink>
        <FooterLink href="/referrals">{t('links.referrals')}</FooterLink>
        <FooterLink href="/forbidden-jurisdictions">{t('links.forbidden_jurisdictions')}</FooterLink>
        <FooterLink href="/prohibited-merchant-types">{t('links.prohibited')}</FooterLink>
        <FooterLink href="/faq">{t('links.faq')}</FooterLink>
      </LinksColumn>
      <LinksColumn title={t('links.titles.support')}>
        <FooterLink href="/why-has-numberx-charged-me">{t('links.why_charged')}</FooterLink>
        <FooterLink href="/buyer-support">{t('links.buyer_support')}</FooterLink>
        <FooterLink href="/partner-support">{t('links.partner_support')}</FooterLink>
      </LinksColumn>
      <LinksColumn title={t('links.titles.social')}>
        <div className="flex w-full flex-col gap-6 pl-2 xl:gap-20">
          <li className="flex w-full flex-wrap gap-1">
            <IconLink href="https://t.me/numberx_uk" icon={<TelegramIcon />} />
            <IconLink href="https://instagram.com/numberxdigital?igshid=MzRlODBiNWFlZA==" icon={<InstagramIcon />} />
            <IconLink href="https://www.linkedin.com/company/number-x-usa/" icon={<LinkedInIcon />} />
          </li>
          <AvailabilityBlock />
        </div>
      </LinksColumn>
    </div>
  )
}

export { LinksBlock }
