import axios from 'axios'
import { produce } from 'immer'
import { STATE } from 'lib/constants/api'
import { create } from 'zustand'

interface IPaymentState {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  close: () => void
  loadingState: STATE
  redirectUrl: string | null
  setLoadingState: (loadingState: STATE) => void
  create: (payment: Data.Payment) => void
}

const usePaymentController = create<IPaymentState>()((set, get) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
  close: () => set(() => ({ isOpen: false, redirect_url: null })),
  loadingState: STATE.IDLE,
  redirectUrl: null,
  setLoadingState: (loadingState) =>
    set(
      produce<IPaymentState>((state) => {
        state.loadingState = loadingState
      }),
    ),
  create: async (payment: Data.Payment) => {
    get().setLoadingState(STATE.LOADING)

    try {
      const response = await axios.post<{ redirect_url: string }>('/create', {
        payment: {
          number: payment.title,
          amount: payment.price,
          description: payment.description,
        },
        authenticity_token: '',
      })

      set(
        produce<IPaymentState>((state) => {
          state.loadingState = STATE.LOADED
          state.redirectUrl = response.data.redirect_url
        }),
      )
    } catch (error) {
      console.error(error)
      get().setLoadingState(STATE.FAILED)
    }
  },
}))

export { usePaymentController }
