import { FC } from 'react'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import ImgOne from 'images/demo/1.jpg'
import ImgTwo from 'images/demo/2.jpg'
import ImgThree from 'images/demo/3.jpg'

import { Card } from './components/Card/Card'

export type TCardItem = {
  rating: number
  image: string
} & Data.Payment

const items: TCardItem[] = [
  {
    title: 'Photography Masterclass',
    description:
      'Mobile Photography course guides you through mastering the art of taking exceptional photos using just your smartphone.',
    price: 199,
    rating: 4,
    image: ImgOne,
  },
  {
    title: 'Character Creation',
    description: 'This course covers the entire process of crafting high-quality game characters',
    price: 260,
    rating: 4,
    image: ImgTwo,
  },
  {
    title: 'Course Figma Premium +++',
    description:
      'Discover opportunities in design, exploring strategies to turn your design skills into a successful venture.',
    price: 90,
    rating: 4,
    image: ImgThree,
  },
]

const CardBlock: FC<{}> = () => {
  return (
    <ResponsiveBlock size="wide" className="">
      <ul className="grid grid-cols-3 gap-20 max-xl:gap-8 max-lg:grid-cols-1">
        {items.map((item, index) => (
          <Card key={index} item={item} index={index} />
        ))}
      </ul>
    </ResponsiveBlock>
  )
}

export { CardBlock }
