import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import Logo from 'images/demo/logo.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const Footer: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)

  return (
    <footer className="flex w-full justify-center py-8 max-xl:py-4 max-lg:py-8">
      <ResponsiveBlock size="wide">
        <div className="flex flex-col items-center justify-center gap-2 text-text-black-alt">
          <Text tagName="span" className="font-tertiary-bold---12pt text-center">
            {t('footer.title')}
          </Text>
          <Logo className="" />
        </div>
      </ResponsiveBlock>
    </footer>
  )
}

export { Footer }
